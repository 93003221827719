import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Portal from '@mui/material/Portal';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { PageSection } from '@shutterstock-private/react-data-tracking';
import { useTranslation } from 'next-i18next';
import { useCallback, useState } from 'react';

import { getLastUploadedMediaType, getMediaTypeFromMedia } from '@/components/UploadSnackbar/utils';
import { IconButton } from '@/components/WithTracking/IconButton';
import { CommonTrackActions, UploadSnackbarPageSections } from '@/constants/analytics';
import { MediaType } from '@/constants/media';
import { useConfirmationComponent } from '@/hooks/useConfirmationComponent';
import { ChevronUpIcon } from '@/icons/ChevronUpIcon';
import { CloseIcon } from '@/icons/CloseIcon';
import { NAMESPACE_COMMON } from '@/translations/namespaces';
import { debounce } from '@/utils/debounce';

import { useUploadsContext } from './Context';
import { UploadStates, WidgetStates } from './Context/constants';
import { UploadItem } from './UploadItem';
import { UploadProgress } from './UploadProgress';
import { uploadSnackStyles } from './UploadSnackbar.styles';

export const getUploadsByState = (statuses, uploadList) =>
  [].concat(...statuses.map((status) => uploadList.filter((uploadItem) => uploadItem.state === status)));

export const getTitle = (uploadList, currentlyInProgress, t) => {
  const everyBy = (states) => uploadList.every((uploadItem) => states.includes(uploadItem.state));

  const allCancelled = everyBy([UploadStates.Canceled, UploadStates.Errored]);
  const allErrored = everyBy([UploadStates.Errored]);
  const allCompleted = everyBy([UploadStates.Done]);
  const someComplete = uploadList.filter((uploadItem) => uploadItem.state === UploadStates.Done).length;
  let title = '';

  if (currentlyInProgress > 1) {
    title = t('uploading_n_items', { NUMBER: currentlyInProgress });
  } else if (currentlyInProgress === 1) {
    title = t('uploading_1_item');
  } else if (allErrored) {
    title = t('all_upload_failed');
  } else if (allCancelled) {
    title = t('all_upload_cancelled');
  } else if (allCompleted) {
    title = t('all_upload_complete');
  } else if (someComplete) {
    title = t('n_upload_complete', { NUMBER: someComplete });
  }

  return title;
};

export const UploadSnackbar = () => {
  const theme = useTheme();

  const [progressIsSticky, setProgressIsSticky] = useState(false);
  const onScrollDetailsInner = debounce(
    ({ target: { scrollTop } = {} } = {}) => setProgressIsSticky(scrollTop > 0),
    20,
  );
  const { t } = useTranslation(NAMESPACE_COMMON);
  const { closeConfirmation, ConfirmationAnchor } = useConfirmationComponent();
  const { uploadList, progress, widgetState, setWidgetState, uploadsInProgress, cancelAllUploads, clearList } =
    useUploadsContext();

  const onChange = useCallback(
    (_, expanded) => setWidgetState(expanded ? WidgetStates.Open : WidgetStates.Minimized),
    [setWidgetState],
  );
  const currentlyInProgress = uploadsInProgress.length;
  const isMultiUpload = uploadList.length > 1;
  let lastUploadedMediaType = MediaType.Photo;

  if (uploadList.length > 0) {
    // If the upload modal stays open and users upload one media at a time - upload list does not clear this is considered a multi upload
    // In this case set the media type to be the last one uploaded
    lastUploadedMediaType = isMultiUpload
      ? getLastUploadedMediaType({ uploadList })
      : getMediaTypeFromMedia({ media: uploadList[uploadList.length - 1].mediaType });
  }

  const allCompleted = uploadList.every((uploadItem) => uploadItem.state === UploadStates.Done);

  const orderedUploadList =
    widgetState === WidgetStates.Open
      ? getUploadsByState(
          [UploadStates.Uploading, UploadStates.Queued, UploadStates.Errored, UploadStates.Canceled, UploadStates.Done],
          uploadList,
        )
      : uploadList;

  const cancelConfirm = useCallback(() => {
    closeConfirmation();
    cancelAllUploads();
    clearList();
    setWidgetState(WidgetStates.Closed);
  }, [closeConfirmation, cancelAllUploads, clearList, setWidgetState]);

  const onWidgetClose = () => {
    const failedUploads = getUploadsByState([UploadStates.Errored, UploadStates.Canceled], uploadList);

    if (currentlyInProgress || failedUploads.length) {
      // should show the small modal
      cancelConfirm();
    } else if (allCompleted) {
      clearList();
      setWidgetState(WidgetStates.Closed);
    }
  };

  const title = getTitle(uploadList, currentlyInProgress, t);

  return widgetState === WidgetStates.Closed ? null : (
    <PageSection value={UploadSnackbarPageSections.uploadSnackbar}>
      <Portal>
        <Box sx={uploadSnackStyles(theme).uploadSnackbar}>
          <IconButton
            data-testid="snackbar-close-icon"
            aria-label="close"
            sx={{
              position: 'absolute',
              zIndex: 1,
              right: 0,
              top: 0,
              color: ({ palette }) => palette.white?.main,
            }}
            onClick={onWidgetClose}
            size="large"
            trackAction={CommonTrackActions.close}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <Accordion
            expanded={widgetState === WidgetStates.Open}
            onChange={onChange}
            sx={{
              '&.MuiAccordion-root': {
                '&&': ({ spacing }) => ({
                  margin: 0,
                  borderRadius: `${spacing(2.5)} ${spacing(2.5)} ${spacing(2)} ${spacing(2)}`,
                }),
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ChevronUpIcon sx={({ palette }) => ({ color: palette.white?.main })} />}
              aria-label="Upload progress"
              aria-controls="upload-progress"
              id="upload-progress-header"
              sx={{
                '&.MuiAccordionSummary-root': ({ palette, spacing }) => ({
                  background: palette.primary?.main,
                  color: palette.white?.main,
                  padding: `0 ${spacing(4.5)} 0 ${spacing(2)}`,
                  '&&': {
                    borderRadius: `${spacing(2)} ${spacing(2)} 0 0`,
                    minHeight: spacing(6),
                    maxHeight: spacing(6),
                  },
                }),
                '&.MuiAccordionSummary-content': {
                  '&&': {
                    margin: 0,
                    minWidth: 0,
                  },
                },
              }}
            >
              <Typography variant="body2" noWrap>
                {title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={uploadSnackStyles(theme).accordionDetails}>
              <UploadProgress
                progress={progress}
                allCompleted={allCompleted}
                lastUploadedMediaType={lastUploadedMediaType}
                isSticky={progressIsSticky}
              />
              <Box sx={uploadSnackStyles(theme).accordionDetailsInner} onScroll={onScrollDetailsInner}>
                {orderedUploadList.map((upload) => (
                  <UploadItem
                    key={upload.id}
                    name={upload.file.name}
                    state={upload.state}
                    processStatus={upload.processData.attributes?.status}
                    onCancel={upload.cancel}
                    onRetry={upload.allowRetry ? upload.retry : undefined}
                    mediaType={upload.mediaType}
                    failedReason={upload.failedReason}
                    thumbnailUrl={upload.thumbnailUrl}
                    type={upload.file.type}
                  />
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
          <ConfirmationAnchor />
        </Box>
      </Portal>
    </PageSection>
  );
};
