import SvgIcon from '@mui/material/SvgIcon';

export const VectorsIcon = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 10C19.262 10 18.614 10.405 18.268 11H15.647C17.688 9.35 19 6.829 19 4V2H17V4C17 7.866 13.866 11 10 11H5.732C5.386 10.405 4.738 10 4 10C2.896 10 2 10.896 2 12C2 13.104 2.896 14 4 14C4.738 14 5.382 13.602 5.729 13.006L8.354 13C6.312 14.65 5.01 17.171 5.01 20V22H7V20C7 16.134 10.134 13 14 13H18.268C18.614 13.595 19.262 14 20 14C21.104 14 22 13.104 22 12C22 10.896 21.104 10 20 10"
    />
  </SvgIcon>
);
